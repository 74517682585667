import { gql } from "@apollo/client";
import { FRAGMENT_RECIPE } from "./recipes";

export const FRAGMENT_WEEKLYPLAN = gql`
  fragment Weeklyplandetails on Weeklyplan {
    id
    name
    times
    weekdays
    baskets
    user {
      id
      username
    }
    addedmeals
    # ingredientlist
    recipes {
      ...Recipedetails
    }
  }
  ${FRAGMENT_RECIPE}
`;

export const GET_WEEKLYPLANS = gql`
  query getPlans($uid: ID!) {
    weeklyplans(where: { user: $uid }) {
      ...Weeklyplandetails
    }
  }
  ${FRAGMENT_WEEKLYPLAN}
`;

export const ADD_MEALPLAN_MUTATION = gql`
  mutation AddMealPlanMutation($data: WeeklyplanInput!) {
    createWeeklyplan(input: { data: $data }) {
      weeklyplan {
        id
        name
        times
        weekdays
        baskets
        user {
          id
          username
        }
        addedmeals
        recipes {
          id
          title
        }
      }
    }
  }
`;
export const EDIT_MEALPLAN_MUTATION = gql`
  mutation EditMealPlanMutation($data: editWeeklyplanInput!, $id: ID!) {
    updateWeeklyplan(input: { where: { id: $id }, data: $data }) {
      weeklyplan {
        name
        times
        weekdays
        baskets
        user {
          id
          username
        }
        addedmeals
      }
    }
  }
`;
export const DELETE_MEALPLAN_MUTATION = gql`
  mutation DeleteMealPlanMutation($id: ID!) {
    deleteWeeklyplan(input: { where: { id: $id }}) {
      weeklyplan {
        name
        times
        weekdays
        baskets
        user {
          id
          username
        }
        addedmeals
        
      }
    }
  }
`;
