import { camalize, convertTr } from "./text";
import { DateTime, Info } from "luxon";

export function prepareIngredientList(newBoard) {
  const t = { ...newBoard.quoteMap };
  delete t["iota"];
  let plan = [];
  Object.keys(t).forEach((element) => {
    t[element].forEach((el) => {
      plan = [
        ...plan,
        ...el.ingredients.map((r) => {
          return { recipe: el.id, ing: r.details };
        }),
      ];
    });
  });

  const plan1 = plan.map((s) => [s.recipe, ...s.ing]);
  const newP = [];
  plan1.forEach((el) => {
    if (el.length > 2) {
      const key = convertTr(camalize(el[3]));
      newP[key] = {
        recipes: newP[key]?.recipes ? [...newP[key]?.recipes, el[0]] : [el[0]],
        orj: el[3],
        amount: newP[key]?.amount
          ? [
              ...newP[key].amount,
              {
                number: el[1],
                unit: el[2],
              },
            ]
          : [
              {
                number: el[1],
                unit: el[2],
              },
            ],
      };
    }
  });

  let newP2 = [];
  Object.keys(newP).forEach((el) => {
    let amountKeys = [];
    newP[el].amount.forEach((el1) => {
      const key = convertTr(camalize(el1.unit));
      amountKeys[key] = {
        orj: el1.unit,
        numbers: amountKeys[key]?.numbers
          ? eval(amountKeys[key].numbers) + eval(el1.number)
          : el1.number,
      };
    });

    newP2[el] = {
      recipes: newP[el]?.recipes,
      orj: newP[el].orj,
      amount: Object.values(amountKeys).map((s) => ({
        number: s.numbers,
        unit: s.orj,
      })),
    };
  });
  return newP2;
}

function onlyUnique(value, index, self) {
  return self.indexOf(value) === index;
}

export function makeAddPlanDBData(newBoard, boardData, user, ingredientList) {
  const t = { ...newBoard.quoteMap };
  delete t["iota"];
  const plan = [];
  let addedMeals = [];
  Object.keys(t).forEach((element) => {
    const [day, time] = element.split(".");
    const otag = boardData.times.filter((f) => f.name == time)[0];
    plan.push({
      day,
      time: otag,
      meals: t[element].map((s) => s.id),
    });
    addedMeals = [...addedMeals, ...t[element].map((s) => s.id)];
  });
  addedMeals = addedMeals.filter(onlyUnique);

  return {
    name: boardData.name,
    times: boardData.times,
    weekdays: boardData.weekTags,
    baskets: plan,
    user: user.id,
    // ingredientlist: Object.values(ingredientList),
    // addedmeals: boardData.addedMeals.map((s) => {
    //   return { ...s, recipe: s.recipe.id };
    // }),
    recipes: addedMeals,
  };
}

export function makeYesterdayTodayTomorrow(cDatas) {
  let yesterday = [];
  let today = [];
  let tomorrow = [];
  if (cDatas.calendars.length > 0) {
    const calendar = cDatas.calendars[0];
    calendar.events.forEach((el1) => {
      el1.events.forEach((el2) => {
        if (DateTime.fromISO(el2.start).hasSame(DateTime.local(), "day")) {
          today.push(el2);
        }
        if (
          DateTime.fromISO(el2.start).hasSame(
            DateTime.local().plus({ days: -1 }),
            "day"
          )
        ) {
          yesterday.push(el2);
        }
        if (
          DateTime.fromISO(el2.start).hasSame(
            DateTime.local().plus({ days: 1 }),
            "day"
          )
        ) {
          tomorrow.push(el2);
        }
      });
    });
  }

  return [yesterday, today, tomorrow];
}

export function fillDays(cDatas, days) {
  if (cDatas.calendars.length == 0) return [];
  const weeklyplans = cDatas.calendars[0].weeklyplans;
  return days.map((t, i) => {
    const planId = t.groupId;
    const mealTime = DateTime.fromISO(t.start);

    const weeklyplan = weeklyplans.filter((d) => d.id == planId)[0];
    let recipes = [];
    weeklyplan.recipes.forEach((r) => {
      recipes[`_${r.id}`] = r;
    });

    const meals = weeklyplan.baskets
      .filter(
        (a) =>
          a.day ==
          mealTime.toFormat("ccc.hh:mm a").split(".")[0].toLocaleLowerCase()
      )
      .map((b) => {
        return {
          ...b,
          meals: b.meals.map((r) => recipes[`_${r}`]),
          time: {
            name: b.time.name,
            time: DateTime.fromFormat(
              `${mealTime.toFormat("yyyy-MM-dd")} ${b.time.time}`,
              "yyyy-MM-dd h:mm a"
            ).toISO(),
          },
        };
      })
      .filter((c) =>
        DateTime.fromISO(c?.time?.time).hasSame(mealTime, "hour")
      )[0];
    return { ...t, ...meals };
  });
}

export function calculateUpcoming(today, tomorrow) {
  let upcoming = [];
  if (today.length == 0 && tomorrow.length == 0) return upcoming;
  today.forEach((c) => {
    if (
      DateTime.fromISO(c.time?.time)
        .diff(DateTime.local(), ["minutes"])
        .toObject().minutes > 0 &&
      upcoming.length == 0
    ) {
      upcoming.push(c); //today
    }
  });
  if (upcoming.length == 0) upcoming.push(tomorrow[0]); //today
  return upcoming;
}
