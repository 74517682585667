import { gql } from "@apollo/client";
import { FRAGMENT_WEEKLYPLAN } from "./mealplan";

export const GET_CALENDARS = gql`
  query getCalendar($uid: ID!) {
    calendars(where: { user: $uid }) {
      id
      name
      events
      weeklyplans {
        ...Weeklyplandetails
      }
      user {
        id
        username
      }
    }
  }
  ${FRAGMENT_WEEKLYPLAN}
`;

export const ADD_CALENDAR_MUTATION = gql`
  mutation AddCalendarMutation($data: CalendarInput!) {
    createCalendar(input: { data: $data }) {
      calendar {
        id
        name
        events
        user {
          id
          username
        }
      }
    }
  }
`;
export const EDIT_CALENDAR_MUTATION = gql`
  mutation EditCalendarMutation($data: editCalendarInput!, $id: ID!) {
    updateCalendar(input: { where: { id: $id }, data: $data }) {
      calendar {
        id
        name
        events
        user {
          id
          username
        }
      }
    }
  }
`;
